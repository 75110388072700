import React, { useId, useState } from 'react';

import { CFColorTokenProp } from '../../../theme/tokens/contentful-color';
import { WidgetProps, DEFAULT_BUTTON_TEXT, SharedProps } from '../shared';
import { Widget } from '../styles';
import cx from 'classnames';

import {
  MobilePhoneNumberInput,
  MobilePhoneNumberLabel,
  MobilePhoneNumberButton
} from './MobilePhoneNumberWidget.styles';
import { usePageDataContext } from '~/context/PageData';
import { BannerMessage } from '../BannerMessage';
import RichText from '~/components/RichText';

import { RenderNode } from '@contentful/rich-text-react-renderer';
import { Block, BLOCKS, Inline } from '@contentful/rich-text-types';

interface MobilePhoneNumberWidgetProps extends WidgetProps, SharedProps {
  useDropshadow?: boolean;
  responsive?: boolean;
}

const ctaOverrideOptions: RenderNode = {
  [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children) => {
    return (
      <p className="w-fit items-center justify-center text-[10px] leading-4">
        {children}
      </p>
    );
  }
};

export function MobilePhoneNumberWidget({
  autocompleteAttribute,
  buttonColor = 'ginger',
  buttonHoverColor = 'green',
  borderColor = '#3BBFAD',
  buttonText = DEFAULT_BUTTON_TEXT,
  returningUserButtonText,
  buttonContent,
  label,
  privacyDisclaimerDropdown,
  errorMessage,
  errorPosition,
  isReturning,
  showErrorBanner,
  inputProps,
  segmentEventName,
  submitted,
  successMessage = 'Thank You!',
  useDropshadow = false,
  responsive
}: MobilePhoneNumberWidgetProps) {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const inputId = useId();

  const showSuccessMessage = () => {
    return (!submitted && !!segmentEventName) || !segmentEventName;
  };

  const { cookies: { userCTAInteraction } = {} } = usePageDataContext();
  const interactedWithCTA =
    userCTAInteraction && userCTAInteraction?.includes(segmentEventName || '');

  const { id, value } = inputProps ?? {};

  return (
    <div
      className={cx(
        'relative z-[3] flex h-full max-h-16 flex-row items-center justify-center',
        {
          'w-fit': !responsive
        }
      )}
    >
      <Widget alignItems="center">
        <MobilePhoneNumberLabel
          htmlFor={id ?? inputId}
          floatLabel={!!value || isFocused}
        >
          {showSuccessMessage() ? label : ''}
        </MobilePhoneNumberLabel>
        <MobilePhoneNumberInput
          {...inputProps}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className="font-medium"
          id={id ?? inputId}
          placeholder=""
          autoComplete={autocompleteAttribute ?? 'tel'}
          borderColor={
            showErrorBanner && errorMessage ? '#D31400' : borderColor
          }
          floatLabel={!!value || isFocused}
          submitted={submitted && !!segmentEventName}
          value={submitted && !!segmentEventName ? successMessage : value}
          data-e2e="phone-number"
          data-testid="mobile-phone-number-input"
          useDropshadow={useDropshadow}
          isReturning={isReturning}
          responsive={responsive}
        />
        {showSuccessMessage() && (
          <MobilePhoneNumberButton
            type="submit"
            cfButtonColor={buttonColor as CFColorTokenProp}
            cfButtonHoverColor={buttonHoverColor as CFColorTokenProp}
            useDropshadow={useDropshadow}
            data-e2e="btn-getting-started"
            data-testid="btn-getting-started"
          >
            {(!segmentEventName && isReturning) || interactedWithCTA
              ? returningUserButtonText ?? (buttonContent || buttonText)
              : buttonContent || buttonText}
          </MobilePhoneNumberButton>
        )}
      </Widget>
      {!!privacyDisclaimerDropdown && (
        <div
          className={cx(
            'absolute left-0 top-full -mt-1 h-8 w-full overflow-hidden rounded-b-lg bg-[#F8F7F3] transition-all duration-300',
            {
              'max-h-14 opacity-100': isFocused,
              'max-h-0 opacity-0': !isFocused
            }
          )}
        >
          <div className="flex h-full items-center justify-center">
            <RichText
              data={privacyDisclaimerDropdown}
              overrideOptions={ctaOverrideOptions}
            />
          </div>
        </div>
      )}
      {showErrorBanner && errorMessage && (
        <BannerMessage
          message={errorMessage}
          position={errorPosition}
          isDisclaimerPresent={!!privacyDisclaimerDropdown}
          variant="error"
        />
      )}
      {submitted && (
        <BannerMessage
          message={successMessage}
          isDisclaimerPresent={!!privacyDisclaimerDropdown}
          position="bottom"
          variant="success"
        />
      )}
    </div>
  );
}
