import cx from 'classnames';
import {
  ErrorExplanationMarkCircleIcon,
  ErrorCheckCircleIcon,
  SuccessCheckCircleIcon
} from './icons';

interface Props {
  message: string;
  isDisclaimerPresent?: boolean;
  variant?: 'error' | 'success';
  position?: 'top' | 'bottom';
}
export function BannerMessage({
  message,
  position,
  variant,
  isDisclaimerPresent
}: Props) {
  return (
    <>
      {isDisclaimerPresent ? (
        <div
          className={cx(
            `z-1 absolute top-full flex h-8 w-full items-center rounded-b-lg text-[10px] pl-4 pb-[5px] pt-[6px] -mt-1`,
            position === 'top' ? 'bottom-full' : 'top-full',
            variant === 'error' && 'bg-[#FFE2DE]',
            variant === 'success' && 'bg-[#CCF6E5]'
          )}
        >
          {variant === 'error' && (
            <ErrorCheckCircleIcon className="mr-2" />
          )}
          {variant === 'success' && <SuccessCheckCircleIcon className="mr-2" />}
          {message}
        </div>
      ) : (
        <div
          className={cx(
            `font-size-xs absolute z-10 mt-2 flex w-full items-center rounded-lg p-2 text-left text-xs font-normal`,
            position === 'top' ? 'bottom-full' : 'top-full',
            variant === 'error' && 'bg-[#fcefee] text-[#D31400]',
            variant === 'success' && 'bg-[#ebfdf5] text-[#2CB87F]'
          )}
        >
          {variant === 'error' && (
            <ErrorExplanationMarkCircleIcon className="mr-2" />
          )}
          {variant === 'success' && <SuccessCheckCircleIcon className="mr-2" />}
          {message}
        </div>
      )}
    </>
  );
}
