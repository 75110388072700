import styled from '../../../theme/styled-with-theme';
import { pxToRem } from '../../../styles/utils';
import { mediaQueries } from '../../../styles/media-queries';

import { Container, Input, Button as StyledButton } from '../styles';
import { Label } from '../../Input/styles';

const REGULAR_DESKTOP_WIDTH = 312;
const REGULAR_TABLET_WIDTH = 280;
const DROPSHADOW_DESKTOP_WIDTH = 410;
const DROPSHADOW_TABLET_WIDTH = 410;
const DROPSHADOW_MOBILE_WIDTH = 270;
const INPUT_HEIGHT = 56;

export const PhoneNumberWrapper = styled(Container)`
  max-height: ${pxToRem(64)};
  width: fit-content;
  position: relative;
`;

export const MobilePhoneNumberInput = styled(Input, {
  shouldForwardProp: (prop) =>
    !['floatLabel', 'useDropshadow', 'isReturning', 'responsive'].includes(prop)
})<{
  floatLabel: boolean;
  submitted?: boolean;
  useDropshadow?: boolean;
  isReturning?: boolean;
  borderColor?: string;
  responsive?: boolean;
}>`
  height: ${pxToRem(INPUT_HEIGHT)};
  width: ${({ responsive }) =>
    responsive ? '100%' : pxToRem(REGULAR_DESKTOP_WIDTH)};
  opacity: 1;
  flex: 1 1 auto;
  line-height: ${pxToRem(16)};
  background-repeat: no-repeat;
  text-align: left;
  background-position: 4% 65%;
  &[disabled] {
    background-color: ${({ theme }) => theme.color.white};
  }

  ${({ submitted, theme }) =>
    submitted
      ? `background-image: url(/images/green-checkmark-circle.svg);
    background-position-y: center;
    line-height: ${pxToRem(INPUT_HEIGHT)} !important;
    padding: 0 0 0 ${pxToRem(65)} !important;

    &[disabled] {
      background-color: ${theme.color.emailInputBackground} !important;
    }`
      : ''}

  ${({ useDropshadow, theme }) =>
    useDropshadow
      ? `box-shadow: 6px 6px 0 0 ${theme.cfColor.blackberry};
    border-color: ${theme.cfColor.blackberry} !important;
    border-radius: ${pxToRem(8)} !important;`
      : ''}

  ${({ theme, floatLabel, borderColor }) => `
    border: ${pxToRem(1)} solid ${borderColor};
    border-radius: ${pxToRem(8)};
    padding: ${
      floatLabel
        ? `${pxToRem(28)} ${theme.spacing.m} ${pxToRem(18)}`
        : `${theme.spacing.m}`
    };
    padding-right: ${pxToRem(130)};
    font-size: ${pxToRem(16)};

    ::placeholder {
      font-size: ${pxToRem(14)};
    }
  `}

  ${mediaQueries[0]} {
    height: ${pxToRem(56)};
    width: ${pxToRem(250)};
    padding: ${({ theme, floatLabel }) =>
      floatLabel
        ? `${theme.spacing.ml} ${theme.spacing.sm} ${theme.spacing.sm}`
        : theme.spacing.sm};
    padding-right: ${pxToRem(100)};
    font-size: ${pxToRem(16)};

    border-radius: ${pxToRem(8)};

    ::placeholder {
      font-size: ${pxToRem(14)};
    }
  }

  @media (min-width: 321px) and (max-width: 350px) {
    width: ${({ useDropshadow }) =>
      useDropshadow ? pxToRem(DROPSHADOW_MOBILE_WIDTH) : pxToRem(280)};
    padding-left: ${({ theme }) => theme.spacing.m};
    font-size: ${pxToRem(16)};
    ::placeholder {
      font-size: ${pxToRem(14)};
    }
  }

  ${mediaQueries.tablet} {
    width: ${({ useDropshadow }) =>
      pxToRem(useDropshadow ? DROPSHADOW_TABLET_WIDTH : REGULAR_TABLET_WIDTH)};
    font-size: ${pxToRem(16)};
    ::placeholder {
      font-size: ${pxToRem(14)};
    }
  }

  ${mediaQueries.desktop} {
    width: calc(
      ${({ useDropshadow, responsive }) =>
          responsive
            ? '100%'
            : pxToRem(
                useDropshadow ? DROPSHADOW_DESKTOP_WIDTH : REGULAR_DESKTOP_WIDTH
              )} + ${({ isReturning }) => (isReturning ? '3.5rem' : '0px')}
    );
    border-radius: ${pxToRem(8)};
    font-size: ${pxToRem(16)};

    ::placeholder {
      font-size: ${pxToRem(14)};
    }
  }
`;

export const MobilePhoneNumberLabel = styled(Label)`
  ${mediaQueries[0]} {
    padding: ${({ theme, floatLabel }) =>
      floatLabel
        ? `${theme.spacing['2xs']} 0`
        : `${pxToRem(20)} ${theme.spacing.sm}`};
    font-size: ${({ theme, floatLabel }) =>
      floatLabel ? theme.fontSize['2xs'] : pxToRem(11)};
  }

  @media (min-width: 321px) and (max-width: 350px) {
    padding: ${({ theme, floatLabel }) =>
      floatLabel
        ? `${theme.spacing['2xs']} ${theme.spacing.xs}`
        : `${theme.spacing.ml} ${theme.spacing.m}`};
    font-size: ${({ theme, floatLabel }) =>
      floatLabel ? theme.fontSize['2xs'] : pxToRem(14)};
  }

  ${mediaQueries.tablet} {
    font-size: ${({ theme, floatLabel }) =>
      floatLabel ? theme.fontSize['2xs'] : pxToRem(14)};
  }

  ${mediaQueries.desktop} {
    ${({ theme, floatLabel }) =>
      floatLabel
        ? `
          font-size: .8em;
          padding: 0 4px 0 4px;
          margin-top: ${theme.spacing.s};
          margin-left: ${theme.spacing.sm};
          background-color: #fff;
        `
        : `
          font-size: ${pxToRem(16)};
          padding: ${pxToRem(19)} 0 0 ${theme.spacing.m} !important;
        `}
  }
`;

export const MobilePhoneNumberButton = styled(StyledButton, {
  shouldForwardProp: (prop) => prop !== 'useDropshadow'
})<{
  useDropshadow?: boolean;
}>`
  position: absolute;
  box-sizing: border-box;
  right: ${({ theme }) => theme.spacing.s};

  ${mediaQueries[0]} {
    height: ${pxToRem(40)};
    width: fit-content;
    font-size: ${({ theme }) => theme.fontSize['2xs']};
    right: ${pxToRem(6)};
  }

  ${mediaQueries.min} {
    height: ${pxToRem(40)};
    min-width: ${pxToRem(112)};
    width: auto;
    font-size: ${({ theme }) => theme.fontSize.s};
    border-radius: ${({ theme, useDropshadow }) =>
      useDropshadow
        ? theme.borderRadius.cta_button_inside_dropshadow
        : theme.borderRadius.cta_button_inside};
  }
`;
