interface Props {
  className?: string;
}
export function ErrorExplanationMarkCircleIcon({ className }: Props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_9061_296)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.666626 8.00001C0.666626 3.94992 3.94987 0.666672 7.99996 0.666672C12.05 0.666672 15.3333 3.94992 15.3333 8.00001C15.3333 12.0501 12.05 15.3333 7.99996 15.3333C3.94987 15.3333 0.666626 12.0501 0.666626 8.00001ZM7.99996 4.50001C8.36815 4.50001 8.66663 4.79848 8.66663 5.16667V8.50001C8.66663 8.86819 8.36815 9.16667 7.99996 9.16667C7.63177 9.16667 7.33329 8.86819 7.33329 8.50001V5.16667C7.33329 4.79848 7.63177 4.50001 7.99996 4.50001ZM8.83329 10.6667C8.83329 11.1269 8.4602 11.5 7.99996 11.5C7.53972 11.5 7.16663 11.1269 7.16663 10.6667C7.16663 10.2064 7.53972 9.83334 7.99996 9.83334C8.4602 9.83334 8.83329 10.2064 8.83329 10.6667Z"
          fill="#D31400"
        />
      </g>
      <defs>
        <clipPath id="clip0_9061_296">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function SuccessCheckCircleIcon({ className }: Props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_9112_265)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99996 0.666672C3.94987 0.666672 0.666626 3.94992 0.666626 8.00001C0.666626 12.0501 3.94987 15.3333 7.99996 15.3333C12.05 15.3333 15.3333 12.0501 15.3333 8.00001C15.3333 3.94992 12.05 0.666672 7.99996 0.666672ZM11.138 5.5286C11.3984 5.78895 11.3984 6.21106 11.138 6.47141L7.47136 10.1381C7.21101 10.3984 6.7889 10.3984 6.52855 10.1381L4.86189 8.47141C4.60154 8.21106 4.60154 7.78895 4.86189 7.5286C5.12224 7.26825 5.54435 7.26825 5.8047 7.5286L6.99996 8.72386L10.1952 5.5286C10.4556 5.26825 10.8777 5.26825 11.138 5.5286Z"
          fill="#2CB87F"
        />
      </g>
      <defs>
        <clipPath id="clip0_9112_265">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ErrorCheckCircleIcon({ className }: Props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_336_4645)">
        <path
          d="M7.99984 0.666687C3.94975 0.666687 0.666504 3.94993 0.666504 8.00002C0.666504 12.0501 3.94975 15.3334 7.99984 15.3334C12.0499 15.3334 15.3332 12.0501 15.3332 8.00002C15.3332 3.94993 12.0499 0.666687 7.99984 0.666687ZM11.1379 5.52862C11.3983 5.78897 11.3983 6.21108 11.1379 6.47143L7.47124 10.1381C7.21089 10.3984 6.78878 10.3984 6.52843 10.1381L4.86177 8.47143C4.60142 8.21108 4.60142 7.78897 4.86177 7.52862C5.12212 7.26827 5.54423 7.26827 5.80457 7.52862L6.99984 8.72388L10.1951 5.52862C10.4554 5.26827 10.8776 5.26827 11.1379 5.52862Z"
          fill="#FF867F"
        />
      </g>
      <defs>
        <clipPath id="clip0_9112_265">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
